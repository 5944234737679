<div style="display: flex; flex-direction: row; height: 100%">
  <div class="sidebar hideable" style="width: 200px; background: white;" [class.hidden]="hide">
    <nb-menu [items]="menu"></nb-menu>

    <div style="width: 100%; display: flex; justify-content: center">
      <button ngbButton style="padding: 10px; width: 100%" (click)="tootgleNav()">
        <nb-icon [icon]="'chevron-left-outline'"></nb-icon>
      </button>
    </div>
  </div>
  <div class="sidebar hideable2" style="width: 30px; background: white" [class.hidden2]="hide">
    <button ngbButton style="padding: 10px; width: 100%; height: 100%" (click)="tootgleNav()">
      <nb-icon [icon]="'chevron-right-outline'"></nb-icon>
    </button>
  </div>

</div>
