import {Component, Input, OnInit} from '@angular/core';
import {Service} from "../../../../../../dtos/service";
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {ServicesService} from "../../../../../../services/services.service";
import {ImageUploadModalComponent} from "../../edit-team/image-upload-modal/image-upload-modal.component";
import {SalesService} from "../../../../../../services/sales.service";
import {Sale} from "../../../../../../dtos/sale";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-sale-add-modal',
  templateUrl: './sale-add-modal.component.html',
  styleUrls: ['./sale-add-modal.component.scss']
})
export class SaleAddModalComponent implements OnInit {

  @Input() sale: Sale;

  // @ts-ignore
  constructor(protected ref: NbDialogRef<ServiceAddModalComponent>, private saleService: SalesService, private dialogService: NbDialogService, private toastr: ToastrService) {}

  dismiss() {
    this.ref.close();
  }

  save() {
    this.saleService.updateSale(this.sale).subscribe({
      next: () => {
        this.toastr.success('Angebot gespeichert');
        this.ref.close();
      },
      error: (err) => {
        this.toastr.error('Angebot konnte nicht gespeichert werden');
      }
    });
  }

  open() {
    this.dialogService.open(ImageUploadModalComponent, {context: {}}).onClose.subscribe({
      next: (imgObj) => {
        if (imgObj.save) {
          if (imgObj.img) {
            const reader = new FileReader();

            reader.readAsDataURL(imgObj.img); // read file as data url

            reader.onload = (event) => { // called once readAsDataURL is completed
              this.sale.img = event.target.result;
            };
          } else {
            this.sale.img = null;
          }
        }
      }
    });
  }

  delete() {
    this.saleService.deleteSale(this.sale.id).subscribe({
      next: () => {
        this.toastr.success('Angebot gelöscht');
      },
      error: (err) => {
        this.toastr.error('Angebot konnte nicht geöscht werden');
      }
    });
  }

  ngOnInit(): void {
  }

}
