import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Observable, of} from "rxjs";
import {Service} from "../dtos/service";
import {Sale} from "../dtos/sale";
import {NightshiftFormatted} from "../dtos/nightshift";

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  cachedSales$: Sale[];

  private baseEndpoint: string = this.globals.backendUri + '/sales';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  /**
   * Loads all messages from the backend
   */
  getAll(): Observable<Sale[]> {
    if (!this.cachedSales$) {
      this.loadCache();
      return this.httpClient.get<Sale[]>(this.baseEndpoint);
    }
    return of(this.cachedSales$);
  }

  updateSale(sale: Sale): Observable<Sale> {
    this.invalidateCache();
    return this.httpClient.put<Sale>(this.baseEndpoint, sale);
  }

  deleteSale(id: number): Observable<boolean> {
    this.invalidateCache();
    return this.httpClient.delete<boolean>(this.baseEndpoint + '/' + id);
  }

  loadCache() {
    this.httpClient.get<Sale[]>(this.baseEndpoint).subscribe({
      next: (res) => {
        this.cachedSales$ = res;
      }
    });
  }

  private invalidateCache() {
    this.cachedSales$ = null;
  }
}
