import { Component, OnInit } from '@angular/core';
import {HeaderService} from "../../services/header.service";

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  constructor(private headerService: HeaderService) { }

  ngOnInit(): void {
    this.headerService.disableGhostBar();
  }

}
