<div class="hero-section wf-section">
  <div class="containner-slide w-container">
    <h1 data-w-id="e8c3a2a0-91d4-fea1-73bf-8822a8112a19" class="hero-heading second">Angebote</h1>
  </div>
</div>
<section class="team-circles wf-section">
  <div class="container-2">
    <div *ngIf="sales?.length === 0">
      <h2 style="color:black">Aktuell haben wir leider keine Angebote</h2>
    </div>
    <div class="team-grid">
      <div id="w-node-f7711791-17ec-1bcb-4a97-dc5dbe56f621-427834bf" class="team-card" *ngFor="let sale of sales">
        <img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a55c612aae2_portfolio%202%20-%20wide.svg" loading="lazy" alt="" class="team-member-image" *ngIf="!sale.img">
        <img [src]="sale.img" loading="lazy" alt="" class="team-member-image" style="border-radius: 0;" *ngIf="sale.img">
        <div class="team-member-name">{{sale.product}}</div>
        <div class="team-member-position"><span class="text-span">{{sale.prev_price}}</span> {{sale.new_price}}</div>
      </div>

    </div>
  </div>
</section>
