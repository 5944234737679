import { Component, OnInit } from '@angular/core';
import {Sale} from "../../dtos/sale";
import {SalesService} from "../../services/sales.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-angebote',
  templateUrl: './angebote.component.html',
  styleUrls: ['./angebote.component.scss']
})
export class AngeboteComponent implements OnInit {

  sales: Sale[] = [];

  constructor(private salesService: SalesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.salesService.getAll().subscribe({
      next: (res) => {
        this.sales = res;
      },
      error: (err) => {
        this.toastr.error('Angebote konnten nicht geladen werden');
      }
    });
  }

}
