import { Component, OnInit } from '@angular/core';
import {HeaderService} from "../../../../services/header.service";
import {SidebarService} from "../../administration-services/sidebarService/sidebar.service";
import {NbMenuItem} from "@nebular/theme";
import {AdministrationService} from "../../administration-services/administration.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  constructor(
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private administrationService: AdministrationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  menu: NbMenuItem[] = [];
  form: FormGroup;

  ngOnInit(): void {
    this.headerService.disableGhostBar();
    this.menu = this.sidebarService.getSidebarMenu();

    this.form = this.formBuilder.group({
      oldPass: [''],
      newPass1: [''],
      newPass2: ['']
    });
  }

  changePassword() {
    console.log(this.form);
    if (this.form.value.newPass1 === this.form.value.newPass2) {
      this.administrationService.resetPassword({oldPass: this.form.value.oldPass, newPass: this.form.value.newPass1}).subscribe({
        next: () => {
          this.toastr.success('Passwort geändert');
        },
        error: (err) => {
          this.toastr.error('Passwort konnte nicht geändert werden');
        }
      })
    }
  }
}
