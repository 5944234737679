<nb-card>
  <nb-card-header>Nachtdienst ändern</nb-card-header>
  <nb-card-body>
    {{nachtdienst.date}},

    <input type="text" nbInput fullWidth status="info"  placeholder="Beschreibung" [(ngModel)]="nachtdienst.location">

  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="danger" (click)="remove()" style="margin-right: 15px">Löschen</button>
    <button nbButton hero status="warning" (click)="dismiss()" style="margin-right: 15px">Abbrechen</button>
    <button nbButton hero status="primary" (click)="save()">Speichern</button>
  </nb-card-footer>
</nb-card>
