import {Component, OnInit} from '@angular/core';
import {CoreService} from "../../services/core.service";
import {CoreInfo} from "../../dtos/coreInfo";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  coreInfo: CoreInfo;

  constructor(private coreService: CoreService) { }

  ngOnInit() {
    this.coreInfo = new CoreInfo();
    this.coreService.getCoreInfo().subscribe({
      next: (res) => {
        this.coreInfo = res;
      }
    })
  }

}
