
<div class="loading-screen" [class.loading-screen-done]="loaded">
  <img src="assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg.png" width="48" srcset="assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg-p-500.png 500w, assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg.png 792w" sizes="(max-width: 479px) 43vw, 48px" alt="">
</div>

<app-header></app-header>

<div style="min-height: 100vh; display: flex; flex-direction: column" *ngIf="loaded">
  <router-outlet></router-outlet>

  <app-footer style="margin-top: auto"></app-footer>

  <!--cookie modal-->
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Wir verwenden Cookies</h4>
    </div>
    <div class="modal-body">
      Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. ("Google").
      Google Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. <br>
      <a routerLink="/impressum" (click)="modal.close()">mehr</a>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('deny')">Ablehnen</button>
      <button type="button" class="btn btn-primary" (click)="modal.close('accept')">Akzeptieren</button>
    </div>
  </ng-template>
</div>
