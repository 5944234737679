import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../global/globals';
import {Observable, of} from 'rxjs';
import {Nightshift, NightshiftFormatted} from '../dtos/nightshift';
import {NbDialogService} from '@nebular/theme';
import {Sale} from "../dtos/sale";
import {Team} from "../dtos/employee";
import {NightshiftPdf} from "../dtos/nightshiftpdf";

@Injectable({
  providedIn: 'root'
})
export class NightshiftService {

  cachedNightshifts$: NightshiftFormatted[];

  private baseEndpoint: string = this.globals.backendUri + '/nightshifts';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  /**
   * Loads all messages from the backend
   */
  getAll(): Observable<Nightshift[]> {
    return this.httpClient.get<Nightshift[]>(this.baseEndpoint);
  }

  getAllFormatted(): Observable<NightshiftFormatted[]> {
    if (!this.cachedNightshifts$) {
      this.loadCache();
      return this.httpClient.get<NightshiftFormatted[]>(this.baseEndpoint + '/formatted');
    }
    return of(this.cachedNightshifts$);
  }

  updateNightshift(nightshift: Nightshift): Observable<Nightshift> {
    this.invalidateCache();
    return this.httpClient.put<Nightshift>(this.baseEndpoint, nightshift);
  }

  updateNightshiftPdf(pdf: string): Observable<NightshiftPdf> {
    this.invalidateCache();
    const body = new NightshiftPdf();
    body.pdf = pdf;
    return this.httpClient.put<NightshiftPdf>(this.baseEndpoint + '/pdf', body);
  }

  getNightshiftPdf(): Observable<NightshiftPdf> {
    return this.httpClient.get<NightshiftPdf>(this.baseEndpoint + '/pdf');
  }

  deleteNightshift(id: number): Observable<Nightshift> {
    this.invalidateCache();
    return this.httpClient.delete<Nightshift>(this.baseEndpoint + '/' + id);
  }

  loadCache() {
    this.httpClient.get<NightshiftFormatted[]>(this.baseEndpoint + '/formatted').subscribe({
      next: (res) => {
        this.cachedNightshifts$ = res;
      }
    });
  }

  private invalidateCache() {
    this.cachedNightshifts$ = null;
  }
}
