<nb-card>
  <nb-card-header>Pdf hochladen</nb-card-header>
  <nb-card-body>
    <!-- <label>Datum</label>
     <input type="text" nbInput fullWidth status="info"  placeholder="Datum" [(ngModel)]="">

     <label>Beschreibung</label>
     <input type="text" nbInput fullWidth status="info"  placeholder="Ort" [(ngModel)]="">-->

    <ngx-dropzone (change)="onSelect($event)">
      <ngx-dropzone-label *ngIf="files.length === 0">Pdf auswählen</ngx-dropzone-label>
      <!--<ngx-dropzone-image-preview></ngx-dropzone-image-preview>-->
      <!--<ngx-dropzone-image-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)" [file]="f">
      </ngx-dropzone-image-preview>-->
      <ngx-dropzone-label *ngFor="let f of files">{{f.name}}</ngx-dropzone-label>
    </ngx-dropzone>

  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="warning" (click)="dismiss()" style="margin-right: 15px">Abbrechen</button>
    <button nbButton hero status="primary" (click)="save()">Speichern</button>
  </nb-card-footer>
</nb-card>
