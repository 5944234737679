<div style="color: #1d1d1d; margin-top: 120px">
  <h1 style="color: #1d1d1d">IMPRESSUM</h1>

  <div style="max-width: 1240px; margin-left: auto; margin-right: auto">
    <div style="margin: 15px">
      <div style="width: 100%; text-align: center">
        <h5>St. Anna Apotheke und Drogerie</h5>
        Stadtplatz 22 <br> 4230 Pregarten
      </div>

      <h4>
        Gewerbeinformationen
      </h4>
      <b>Ort der Gewerbeberechtigung</b><br>
      Stadtpl. 22<br>
      4230 Pregarten<br>
      Österreich<br>
      <br>

      <b>UID-Nummer:</b> ATU 78808015<br>
      <b>Firmenbuchnummer:</b> 19494v<br>
      <b>Gerichtsstandort:</b> Pregarten<br>
      <br>
      <b>Geschäftsführung</b><br>
      Pächter Mag. pharm. Harith Arabo<br>
      <br>
      <b>Berufsrechtliche Vorschrift:</b> Apothekengesetz<br>
      <br>
      <p style="text-align: left">
        Das Unternehmen ist eine öffentliche Apotheke nach österreichischem Apothekengesetz, Apothekenbetriebsordnung,
        §§ 50 bis 71 Arzneimittelgesetz und Berufssitte des Apothekerstandes können bei der <a href="https://www.apothekerkammer.at/" style="width: fit-content; margin: 0; padding: 0;">Österreichischen Apothekerkammer</a> angefordert werden.
      </p>

      <h4 style="margin-top: 30px">
        Haftungsausschluss
      </h4>
      <p style="text-align: left">
        Die Informationen auf den Website apotheke-pregarten.at und alle elektronischen Links werden unter folgenden Bedingungen zur Verfügung gestellt:<br>
        Die Website apotheke-pregarten.at und die damit verbundenen Sites bieten kostenlose Informationen zum Thema Gesundheit an. Wir übernehmen keinerlei Verantwortung oder Haftung für die Angaben auf dieser Website.
        <br><br>
        Diese Informationen sind allgemeiner Art, die nicht auf die Bedürfnisse bestimmter Personen abgestimmt sind. Die Inhalte der Website apotheke-pregarten.at können den Rat einer Fachperson (Mediziner und/oder Apotheker) unter keinen Umständen ersetzen. Wir fordern alle Benutzer mit Gesundheitsproblemen dazu auf, im Bedarfsfall immer einen Arzt aufzusuchen und keine Therapien eigenständig zu beginnen, zu verändern oder zu beenden. Der Inhalt dieser Website darf und kann nicht als Ersatz für eine ärztliche Diagnose oder Behandlung verwendet werden.
        <br><br>
        Die Informationen sind nicht notwendigerweise umfassend, komplett, genau oder aktuell. Haftungsansprüche, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung der gebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind ausgeschlossen.
        <br><br>
        Gleiches gilt für abrufbare Inhalte Dritter, auf die mit Quellenangabe verwiesen wird oder zu denen lediglich eine Verknüpfung hergestellt wird. Diese Inhalte Dritter werden von uns inhaltlich nicht kontrolliert und wir haften auch nicht für deren Richtigkeit.
      </p>

      <h4 style="margin-top: 30px">
        Datenschutz
      </h4>
      <p style="text-align: left">
        Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (E-Mail-Adressen, Namen, Anschriften) besteht,
        so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme aller angebotenen Dienste ist - soweit technisch möglich und zumutbar -
        auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten
        Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie E-Mail-Adressen durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche
        Schritte gegen die Versender von so genannten Spam-Mails bei Verstößen gegen dieses Verbot sind ausdrücklich vorbehalten.
      </p>

      <h4 style="margin-top: 30px">
        Google Analytics
      </h4>
      <p style="text-align: left">
        Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. ("Google").
        Google Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen.
        Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
        Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in
        anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in
        den USA übertragen und dort gekürzt. Die IP-Anonymisierung ist auf dieser Website aktiv. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen,
        um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
        Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
        Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin,
        dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das
        Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie
        das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
        <br><br>
        Weitere Informationen über die Datenschutzbestimmungen von Google finden Sie unter <a href="http://www.google.com/policies/privacy/">http://www.google.com/policies/privacy/</a>
      </p>


    </div>
  </div>
</div>
