import { Component, OnInit } from '@angular/core';
import {EmployeeService} from "../../services/employee.service";
import {Employee} from "../../dtos/employee";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  employees: Employee[];
  groupImg;

  constructor(private employeeService: EmployeeService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.employeeService.getAll().subscribe({
      next: (res) => {
        this.employees = res.employees;
        this.groupImg = res.groupDto.img;
      },
      error: (err) => {
        this.toastr.error('Team konnte nicht geladen werden');
      }
    })
  }

}
