import { Component, OnInit } from '@angular/core';
import {Nightshift} from "../../../../../dtos/nightshift";
import {NachtdienstModalComponent} from "../edit-nachtdienstplan/nachtdienst-modal/nachtdienst-modal.component";
import {ImageUploadModalComponent} from "./image-upload-modal/image-upload-modal.component";
import {NbDialogService} from "@nebular/theme";
import {EmployeeService} from "../../../../../services/employee.service";
import {Employee, GroupImg} from "../../../../../dtos/employee";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss']
})
export class EditTeamComponent implements OnInit {

  imgGroup: any;

  employees: Employee[] = [];

  constructor(private dialogService: NbDialogService, private employeeService: EmployeeService, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.employeeService.getAll().subscribe({
      next: (res) => {
        this.employees = res.employees;
        this.imgGroup = res.groupDto.img;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  open(id: number) {
    this.dialogService.open(ImageUploadModalComponent, {context: {}}).onClose.subscribe({next: (imgObj) => {

      if (imgObj.save) {
        const img = imgObj.img;
        if (img) {
          if (id === -1) {
            const reader = new FileReader();

            reader.readAsDataURL(img); // read file as data url

            reader.onload = (event) => { // called once readAsDataURL is completed
              const x: GroupImg = {img: null};
              x.img = event.target.result;
              this.employeeService.replaceGroupImg(x).subscribe({
                next: (res) => {
                  this.imgGroup = event.target.result;
                },
                error: (err) => {
                  console.log(err);
                }
              });
            };
          } else {
            const reader = new FileReader();

            reader.readAsDataURL(img); // read file as data url

            reader.onload = (event) => { // called once readAsDataURL is completed
              this.employees[id].img = event.target.result;
            };
          }
        } else {
          if (id === -1) {
            this.employeeService.replaceGroupImg({img: null}).subscribe({
              next: (res) => {
                this.imgGroup = null;
              },
              error: (err) => {
                console.log(err);
              }
            });
          } else {
            this.employees[id].img = null;
          }
        }
      }

      }});
  }

  addEmployee() {
    this.employees.push({id: null, name: '', occupation: '', img: null});
  }

  removeEmployee(i: number) {
    if (this.employees[i].id) {
      this.employeeService.deleteEmployee(this.employees[i].id).subscribe({
        next: () => {
          this.employees.splice(i, 1);
          this.toastr.success('Mitarbeiter gelöscht');
        },
        error: (err) => {
          this.toastr.error('Mitarbeiter konnte nicht gelöscht werden');
        }
      });
    } else {
      this.employees.splice(i, 1);
    }
  }

  saveEmployee(i: number) {
    this.employeeService.replaceEmployee(this.employees[i]).subscribe({
      next: (res) => {
        this.toastr.success('Änderungen gespeichert');
      },
      error: (err) => {
        this.toastr.success('Änderungen konnten nicht gespeichert werden');
      }
    });
  }
}
