<nb-layout>
  <nb-layout-column style="padding: 0">
    <div style="display: flex; flex-direction: row; margin-top: 80px; height: calc(100% - 80px)">
      <app-sidebar></app-sidebar>
      <div style="margin: 15px">
        <h1 style="color: #1d1d1d">Willkommen im Admin Terminal</h1>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>

