import { Component, OnInit } from '@angular/core';
import {ServicesService} from "../../../../../services/services.service";
import {Service} from "../../../../../dtos/service";
import {Nightshift} from "../../../../../dtos/nightshift";
import {NachtdienstModalComponent} from "../edit-nachtdienstplan/nachtdienst-modal/nachtdienst-modal.component";
import {
  NachtdienstAddModalComponent
} from "../edit-nachtdienstplan/nachtdienst-add-modal/nachtdienst-add-modal.component";
import {ServiceAddModalComponent} from "./service-add-modal/service-add-modal.component";
import {NbDialogService} from "@nebular/theme";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-edit-services',
  templateUrl: './edit-services.component.html',
  styleUrls: ['./edit-services.component.scss']
})
export class EditServicesComponent implements OnInit {

  services: Service[];

  constructor(private servicesService: ServicesService, private dialogService: NbDialogService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.refresh();
  }

  open(service: Service) {
    this.dialogService.open(ServiceAddModalComponent, {context: { service }}).onClose
      .subscribe( () => {
        this.refresh();
      });
  }

  open2() {
    const service = new Service();

    this.dialogService.open(ServiceAddModalComponent, {context: { service }}).onClose
      .subscribe( () => {
        this.refresh();
      });
  }

  refresh(){
    this.servicesService.getAll().subscribe({
      next: (res) => {
        this.services = res;
      },
      error: (err) => {
        this.toastr.error('Leistungen konnten nicht geladen werden');
      }
    });
  }

  toggleFeatured($event: MouseEvent, service: Service) {
    $event.stopPropagation();
    service.featured = !service.featured;
    if (service.featured && !this.validFeatured(this.services)) {
      this.toastr.info('Es können nur maximal 4 Leistungen vorgestellt werden');
      service.featured = false;
    } else {
      this.servicesService.updateService(service).subscribe({
        next: () => {
        },
        error: (err) => {
          this.toastr.error('Änderung konnte nicht gespeichert werden');
        }
      });
    }
  }

  private validFeatured(services: Service[]) {
    let count = 0;
    for (const service of services) {
      if (service.featured) {
        count++;
      }
    }
    return count <= 4;
  }
}
