<nav class="hero-section centered home title-hero-section" style="min-height: 100vh;
  background-color: black;
  background-attachment: fixed;
  background-image: url('assets/images/hr-nightsky.jpg'), url('assets/images/mr-nightsky.jpg'), url('assets/images/lr-nightsky.jpg');
  background-size: cover;
  background-position: bottom;
">

  <div data-w-id="e464d218-f801-55d1-1f50-7da00b5bfb8f" class="container w-container title-hero" style="margin-top: 20px">
    <h1 data-w-id="af901219-579c-2691-e855-549d3168e8a2" style="opacity:1" class="hero-heading">Nachtdienstplan</h1>
  </div>

  <div *ngIf="!nightshifts || nightshifts.length === 0">
    <h3 style="color: white">Im Moment sind noch keine Nachtdienste geplant</h3>
  </div>

  <div style="display: flex; flex-direction: column; align-items: center;">
    <div class="n-box" *ngFor="let nightshift of nightshifts">
      <h5 style="color: white; text-align: center; padding-bottom: 0">{{nightshift.relDay}}</h5>
      <h3 style="color: white; margin-top: 0"> {{nightshift.date}}</h3>
      <p style="color: white">{{nightshift.location}}</p>
    </div>
  </div>


  <div class="container w-container">
    <a class="button" href="https://m.apothekenindex.at/apothekenliste/oesterreich/oberoesterreich">Bereitschaftsdienste anderer Apotheken</a>
  </div>

</nav>
