import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-save-button-hover',
  templateUrl: './save-button-hover.component.html',
  styleUrls: ['./save-button-hover.component.scss']
})
export class SaveButtonHoverComponent implements OnInit {

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
