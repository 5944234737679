import { Component, OnInit } from '@angular/core';
import {HeaderService} from '../../../../../services/header.service';
import {SidebarService} from '../../../administration-services/sidebarService/sidebar.service';
import {FormBuilder} from '@angular/forms';
import {NbDialogService, NbMenuItem} from '@nebular/theme';
import {NightshiftService} from '../../../../../services/nightshift.service';
import {Nightshift} from '../../../../../dtos/nightshift';
import {NachtdienstModalComponent} from './nachtdienst-modal/nachtdienst-modal.component';
import {NachtdienstAddModalComponent} from './nachtdienst-add-modal/nachtdienst-add-modal.component';
import {ToastrService} from 'ngx-toastr';
import {ImageUploadModalComponent} from '../edit-team/image-upload-modal/image-upload-modal.component';
import {GroupImg} from '../../../../../dtos/employee';
import {PdfUploadComponent} from './pdf-upload/pdf-upload.component';

@Component({
  selector: 'app-edit-nachtdienstplan',
  templateUrl: './edit-nachtdienstplan.component.html',
  styleUrls: ['./edit-nachtdienstplan.component.scss']
})
export class EditNachtdienstplanComponent implements OnInit {

  menu: NbMenuItem[] = [];

  nightshifts: Nightshift[] = [];

  constructor(
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private formBuilder: FormBuilder,
    private nightshiftService: NightshiftService,
    private dialogService: NbDialogService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.menu = this.sidebarService.getSidebarMenu();
    this.refresh();
  }

  open(nachtschift: Nightshift) {
    this.dialogService.open(NachtdienstModalComponent, {context: { nachtdienst: nachtschift }}).onClose
      .subscribe( () => {
        this.refresh();
      });
  }

  open2() {
    const nachtschift = new Nightshift();
    if (this.nightshifts && this.nightshifts.length >= 1) {
      nachtschift.date = this.nextDay(this.nightshifts[this.nightshifts.length-1].date);
    } else {
      const a = new Date();

      const zeroPad = (num, places) => String(num).padStart(places, '0');
      nachtschift.date = a.getFullYear()+ '-' + zeroPad(a.getMonth()+1, 2)+ '-' + zeroPad(a.getDate(), 2);
    }
    this.dialogService.open(NachtdienstAddModalComponent, {context: { nachtdienst: nachtschift }}).onClose
      .subscribe( () => {
        this.refresh();
      });
  }

  deletePdf() {
    this.nightshiftService.updateNightshiftPdf(null).subscribe({
      next: () => {
        console.log('success');
      },
      error: () => {
        console.log('error');
      }
    });
  }

  changePdf() {
    this.dialogService.open(PdfUploadComponent, {context: {}}).onClose.subscribe({next: (pdfObj) => {
        const reader = new FileReader();

        reader.readAsDataURL(pdfObj.pdf); // read file as data url

        reader.onload = (event) => { // called once readAsDataURL is completed
          this.nightshiftService.updateNightshiftPdf(String(event.target.result)).subscribe({
            next: () => {
              console.log(event.target.result);
              console.log(String(event.target.result));
              console.log('success');
            },
            error: () => {
              console.log('error');
            }
          });
        };

    }});
  }

  showPDF() {
    this.nightshiftService.getNightshiftPdf().subscribe({
      next: (pdf) => {
        if (pdf.pdf == null) {
          this.toastr.error('Das Dokument ist aktuell nicht verfügbar');
        } else {
          const pdfURL = URL.createObjectURL(this.dataURItoBlob(pdf.pdf));
          window.open(pdfURL, '_blank');
        }
      }
    });
  }


  private dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});


  }

  private nextDay(date: string) {
    const a = new Date(date);
    a.setDate(a.getDate() + 1);


    const zeroPad = (num, places) => String(num).padStart(places, '0');
    return a.getFullYear()+ '-' + zeroPad(a.getMonth()+1, 2)+ '-' + zeroPad(a.getDate(), 2);
  }

  private refresh() {
    this.nightshiftService.getAll().subscribe({
      next: (res) => {
        this.nightshifts = res;
      },
      error: (msg) => {
        this.toastr.error('Nachtdienste konnten nicht geladen werden');
      }
    });
  }
}
