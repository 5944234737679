import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Observable, of} from "rxjs";
import {Employee, GroupImg, Team} from "../dtos/employee";
import {Sale} from "../dtos/sale";
import {CoreInfo} from "../dtos/coreInfo";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  cachedTeam$: Team;

  private baseEndpoint: string = this.globals.backendUri + '/employees';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  /**
   * Loads all messages from the backend
   */
  getAll(): Observable<Team> {
    if (!this.cachedTeam$) {
      this.loadCache();
      return this.httpClient.get<Team>(this.baseEndpoint);
    }
    return of(this.cachedTeam$);
  }

  addEmployee(employee: Employee): Observable<Employee> {
    this.invalidateCache();
    return this.httpClient.post<Employee>(this.baseEndpoint, employee);
  }

  replaceEmployee(employee: Employee): Observable<Employee> {
    this.invalidateCache();
    return this.httpClient.put<Employee>(this.baseEndpoint, employee);
  }

  deleteEmployee(id: number): Observable<boolean> {
    this.invalidateCache();
    return this.httpClient.delete<boolean>(this.baseEndpoint + '/' + id);
  }

  replaceGroupImg(img: GroupImg): Observable<Employee> {
    this.invalidateCache();
    return this.httpClient.put<Employee>(this.baseEndpoint + '/group', img);
  }

  loadCache() {
    this.httpClient.get<Team>(this.baseEndpoint).subscribe({
      next: (res) => {
        this.cachedTeam$ = res;
      }
    });
  }

  private invalidateCache() {
    this.cachedTeam$ = null;
  }
}
