import {Component, Input, OnInit} from '@angular/core';
import {Nightshift} from "../../../../../../dtos/nightshift";
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {NightshiftService} from "../../../../../../services/nightshift.service";
import {ServicesService} from "../../../../../../services/services.service";
import {Service} from "../../../../../../dtos/service";
import {ImageUploadModalComponent} from "../../edit-team/image-upload-modal/image-upload-modal.component";
import {GroupImg} from "../../../../../../dtos/employee";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-service-add-modal',
  templateUrl: './service-add-modal.component.html',
  styleUrls: ['./service-add-modal.component.scss']
})
export class ServiceAddModalComponent implements OnInit {

  @Input() service: Service;

  // @ts-ignore
  constructor(protected ref: NbDialogRef<ServiceAddModalComponent>, private serviceService: ServicesService, private dialogService: NbDialogService,  private toastr: ToastrService) {}

  dismiss() {
    this.ref.close();
  }

  save() {
    this.serviceService.updateService(this.service).subscribe({
      next: () => {
        this.ref.close();
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  open() {
    this.dialogService.open(ImageUploadModalComponent, {context: {}}).onClose.subscribe({
      next: (imgObj) => {
        if (imgObj.save) {
          if (imgObj.img) {
            const reader = new FileReader();

            reader.readAsDataURL(imgObj.img); // read file as data url

            reader.onload = (event) => { // called once readAsDataURL is completed
              this.service.img = event.target.result;
            };
          } else {
            this.service.img = null;
          }
        }
      }
    });
  }

  delete() {
    this.serviceService.deleteService(this.service.id).subscribe({
      next: () => {
        console.log('success');
        this.ref.close();
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  ngOnInit(): void {
  }

}
