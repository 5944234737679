<nb-layout>
  <nb-layout-column style="padding: 0">
    <div style="display: flex; flex-direction: row; margin-top: 80px; height: calc(100% - 80px)">
      <app-sidebar></app-sidebar>

      <form style="width: 100%" [formGroup]="form">

      <app-save-button-hover (save)="save()" (cancel)="cancel()"></app-save-button-hover>

      <nav class="hero-section centered home title-hero-section" style="height: 100vh; display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
">
        <div data-w-id="e464d218-f801-55d1-1f50-7da00b5bfb8f" class="container w-container title-hero">
          <h1 data-w-id="af901219-579c-2691-e855-549d3168e8a2" style="opacity:1" class="hero-heading">
            <input type="text" nbInput shape="rectangle" placeholder="Titel" style="margin-bottom: 15px" formControlName="title" style="min-width: 50%">
          </h1>
          <h1 data-w-id="a53ee65d-8504-36ad-2d31-9fe54a958274" style="opacity:1" class="hero-subheader">
            <input type="text" nbInput shape="rectangle" placeholder="Titel" style="margin-bottom: 15px" formControlName="subtitle" style="min-width: 50%">
          </h1>
        </div>
      </nav>

      <section id="Service" class="features-section wf-section">
        <div class="w-row">
          <div class="column w-col w-col-4" style="padding-left: 0; padding-right: 0; z-index: 0">
            <div class="features-div"><img src="../../../assets/images/webflow/pixel-perfect.svg" width="40" alt="">
              <h3 class="title-features">
                <input type="text" nbInput shape="rectangle" placeholder="Titel" style="margin-bottom: 15px" formControlName="featured1_title" style="min-width: 50%">
              </h3>
              <p class="features-text">
                <textarea rows="5" nbInput fullWidth shape="round"  placeholder="Text Area" formControlName="featured1_description"></textarea>
              </p>
              <label>"Weiter" Button leitet weiter zu:</label>
              <input type="text" nbInput shape="rectangle" placeholder="url" style="margin-bottom: 15px" formControlName="featured1_url" style="min-width: 50%">
            </div>
          </div>
          <div class="column w-col w-col-4" style="padding-left: 0; padding-right: 0">
            <div class="features-div second"><img src="../../../assets/images/webflow/awesome.svg" width="40" alt="">
              <h3 class="title-features">
                <input type="text" nbInput shape="rectangle" placeholder="Titel" style="margin-bottom: 15px" formControlName="featured2_title" style="min-width: 50%">
              </h3>
              <p class="features-text">
                <textarea rows="5" nbInput fullWidth shape="round"  placeholder="Text Area" formControlName="featured2_description"></textarea>
              </p>
              <label>"Weiter" Button leitet weiter zu:</label>
              <input type="text" nbInput shape="rectangle" placeholder="url" style="margin-bottom: 15px" formControlName="featured2_url" style="min-width: 50%">
            </div>
          </div>
          <div class="column w-col w-col-4" style="padding-left: 0; padding-right: 0">
            <div class="features-div third"><img src="../../../assets/images/webflow/responsive.svg" width="40" alt="">
              <h3 class="title-features">
                <input type="text" nbInput shape="rectangle" placeholder="Titel" style="margin-bottom: 15px" formControlName="featured3_title" style="min-width: 50%">
              </h3>
              <p class="features-text">
                <textarea rows="5" nbInput fullWidth shape="round"  placeholder="Text Area" formControlName="featured3_description"></textarea>
              </p>
              <label>"Weiter" Button leitet weiter zu:</label>
              <input type="text" nbInput shape="rectangle" placeholder="url" style="margin-bottom: 15px" formControlName="featured3_url" style="min-width: 50%">
            </div>
          </div>
        </div>
      </section>
      <div id="About-Us" class="section about wf-section">
        <div class="w-container">
          <div class="div-block">
            <div class="about-us-div half-side">
              <h5>
                <input type="text" nbInput shape="rectangle" placeholder="url" style="margin-bottom: 15px" formControlName="about_subheading" style="min-width: 50%">
              </h5>
              <h2 class="section-title">
                <input type="text" nbInput shape="rectangle" placeholder="url" style="margin-bottom: 15px" formControlName="about_heading" style="min-width: 50%">
              </h2>
              <p><strong><em class="emphasized-text">
                <textarea rows="5" nbInput fullWidth shape="round"  placeholder="Text Area" formControlName="about_catchText"></textarea>
              </em></strong></p>

              <label>Dieses Feld ist formatierbar <br>
                &lt;br&gt; für einen Zeilenumbruch <br>
                &lt;b&gt;fett gedruckt&lt;/b&gt; <br>
                &lt;i&gt;kursiv&lt;/i&gt;
              </label>
              <textarea rows="5" nbInput fullWidth shape="round"  placeholder="Text Area" formControlName="about_text"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div id="Skills" class="skills-section wf-section">
        <div class="w-container">
          <h5 class="upperheading-white">
            <input type="text" nbInput shape="rectangle" placeholder="url" style="margin-bottom: 15px" formControlName="oh_subheading" style="min-width: 50%">
          </h5>
          <h2 class="section-title white">
            <input type="text" nbInput shape="rectangle" placeholder="url" style="margin-bottom: 15px" formControlName="oh_title" style="min-width: 50%">
          </h2>

          <label style="color: white">Format: tage|zeiten;tage|zeiten zum Beispiel MO - FR|8:00 - 12:00 & 14:00 - 18:00;SA|8:00 - 12:00</label>

          <textarea rows="5" nbInput fullWidth shape="round"  placeholder="Öffnungszeiten" formControlName="ohd"></textarea>

        </div>
      </div>

      <div id="Contact-Us" class="contact-us-section wf-section">
        <h5>kontaktieren sie uns mit ihren anliegen</h5>
        <h2 class="section-title">kontakt</h2>
        <div class="w-container">
          <div class="contact-row w-row">
            <div class="w-col w-col-6">
              <div class="form-wrapper w-form">
                <form id="email-form" name="email-form" data-name="Email Form" method="get">
                  <label for="name" class="form-text">Name:</label>
                  <input type="text" class="form-field w-input" maxlength="256" name="name" data-name="Name" placeholder="" id="name" required="" disabled>
                  <label for="email" class="form-text">Email AddressE:</label><input type="email" class="form-field w-input" maxlength="256" name="email" data-name="Email" placeholder="" id="email" required="" disabled>
                  <label for="Subject" class="form-text">bETREFF:</label><input type="text" class="form-field w-input" maxlength="256" name="Subject" data-name="Subject" placeholder="" id="Subject" required="" disabled>
                  <label for="Message" class="form-text">nACHRICHT:</label><textarea id="Message" name="Message" maxlength="5000" data-name="Message" required="" placeholder="" class="form-field message w-input" disabled>

                </textarea>
                  <input type="submit" value="Senden" data-wait="Please wait..." class="submit-button w-button" disabled></form>
              </div>
            </div>
            <div class="contact-column w-col w-col-6" style="color: black; text-align: right">
              <p class="emphasized-text right"><strong><em class="emphasized-text">
                <textarea rows="5" nbInput fullWidth shape="round"  placeholder="Text Area" formControlName="contact_catchText"></textarea>
              </em></strong></p>
              <p class="conatct-info-text"><textarea rows="5" nbInput fullWidth shape="round"  placeholder="Text Area" formControlName="contact_text"></textarea></p>
              <p class="conatct-info-text address"><strong class="important-text">ADDRESSE<br></strong>
                <input type="text" nbInput shape="rectangle" placeholder="url" style="margin-bottom: 15px" formControlName="address" style="min-width: 50%">
              <br></p>
              <p class="conatct-info-text"><strong class="important-text">TELEFON &amp; EMAIL<br></strong>
                <label style="color: black; margin-bottom: 5px">telefon</label>
                <input type="text" nbInput shape="rectangle" placeholder="url" style="margin-bottom: 15px" formControlName="telephone" style="min-width: 50%">
                <label style="color: black; margin-bottom: 5px; padding-bottom: 0">email</label>
                <input type="text" nbInput shape="rectangle" placeholder="url" style="margin-bottom: 15px" formControlName="email" style="min-width: 50%"><br></p>
            </div>
          </div>
        </div>
      </div>

      </form>

    </div>
  </nb-layout-column>
</nb-layout>
