<nb-card>
  <nb-card-header>Leistung ändern</nb-card-header>
  <nb-card-body>
    <label>Name</label>
    <input type="text" nbInput fullWidth status="info"  placeholder="Name" [(ngModel)]="sale.product" style="margin-bottom: 5px">

    <div class="icon-service selectable-img" (click)="open()" *ngIf="!sale.img"><img src="assets/images/no-photo.png" style="padding: 7px"></div>
    <div class="icon-service selectable-img" (click)="open()" *ngIf="sale.img"><img [src]="sale.img" style="padding: 7px"></div>

    <label>Alter Preis</label>
    <input type="text" nbInput fullWidth status="info"  placeholder="Alter Preis" [(ngModel)]="sale.prev_price">

    <label>Neuer Preis</label>
    <input type="text" nbInput fullWidth status="info"  placeholder="Neuer Preis" [(ngModel)]="sale.new_price">

  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="danger" (click)="delete()" style="margin-right: 15px" *ngIf="sale.id">Löschen</button>
    <button nbButton hero status="warning" (click)="dismiss()">Abbrechen</button>
    <button nbButton hero status="primary" (click)="save()" style="margin-left: 15px">Speichern</button>
  </nb-card-footer>
</nb-card>
