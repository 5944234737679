import { Component, OnInit } from '@angular/core';
import {NbMenuItem, NbToastrService} from '@nebular/theme';
import {HeaderService} from '../../services/header.service';
import {SidebarService} from './administration-services/sidebarService/sidebar.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

  constructor(
    private headerService: HeaderService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.headerService.disableGhostBar();
  }
}
