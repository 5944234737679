import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Message} from "../../../dtos/message";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../../../global/globals";
import {Password} from "../../../dtos/password";

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  private messageBaseUri: string = this.globals.backendUri + '/users';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  resetPassword(body: Password): Observable<ArrayBuffer> {
    return this.httpClient.put<ArrayBuffer>(this.messageBaseUri + '/password', body);
  }
}
