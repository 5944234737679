import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {map, Observable, of, shareReplay} from "rxjs";
import {Nightshift, NightshiftFormatted} from "../dtos/nightshift";
import {Service} from "../dtos/service";
import {reportUnhandledError} from "rxjs/internal/util/reportUnhandledError";
import {Sale} from "../dtos/sale";

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  cachedServices$: Service[];

  private baseEndpoint: string = this.globals.backendUri + '/services';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  /**
   * Loads all messages from the backend
   */
  getAll(): Observable<Service[]> {
    if (!this.cachedServices$) {
      this.loadCache();
      return this.httpClient.get<Service[]>(this.baseEndpoint);
    }
    return of(this.cachedServices$);
  }

  updateService(service: Service): Observable<Service> {
    this.invalidateCache();
    return this.httpClient.put<Service>(this.baseEndpoint, service);
  }

  deleteService(id: number): Observable<boolean> {
    this.invalidateCache();
    return this.httpClient.delete<boolean>(this.baseEndpoint + '/' + id);
  }

  loadCache() {
    this.httpClient.get<Service[]>(this.baseEndpoint).subscribe({
      next: (res) => {
        this.cachedServices$ = res;
      }
    });
  }

  private invalidateCache() {
    this.cachedServices$ = null;
  }

}
