export class CoreInfo {
  title: string;
  subtitle: string;

  featured1_title: string;
  featured1_description: string;
  featured1_url: string;

  featured2_title: string;
  featured2_description: string;
  featured2_url: string;

  featured3_title: string;
  featured3_description: string;
  featured3_url: string;

  about_subheading: string;
  about_heading: string;
  about_catchText: string;
  about_text: string;

  oh_subheading: string;
  oh_title: string;
  ohd: string;

  contact_catchText: string;
  contact_text: string;

  address: string;
  telephone: string;
  email: string;
}
