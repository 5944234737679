<div class="hero-section wf-section">
  <div class="containner-slide w-container">
    <h1 data-w-id="e8c3a2a0-91d4-fea1-73bf-8822a8112a19" class="hero-heading second">Team</h1>
  </div>

  <div id="Team" class="section wf-section">
    <div class="w-container">
      <img [src]="groupImg" loading="lazy" style="padding: 15px;" sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 940px" alt="">
      <div class="team-row w-row">
        <div class="w-col w-col-4" style="min-height: 430px" *ngFor="let employee of employees">
          <div class="team-wrapper">
            <img [src]="employee.img" alt="" *ngIf="employee.img">
            <img src="assets/images/webflow/man.png" alt="" *ngIf="!employee.img">
          </div>
          <h4 class="team-name">{{employee.name}}</h4>
          <h5 class="team-position">{{employee.occupation}}</h5>g
          
        </div>
      </div>
    </div>
  </div>
</div>
