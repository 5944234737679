import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef, OnInit,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CoreService} from './services/core.service';
import {EmployeeService} from './services/employee.service';
import {NightshiftService} from './services/nightshift.service';
import {SalesService} from './services/sales.service';
import {ServicesService} from './services/services.service';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit{
  @ViewChildren('content') cookieConsent;
  title = 'Apotheke Pregarten';
  loaded = false;
  closeResult = '';

  constructor(
    private coreService: CoreService,
    private employeeService: EmployeeService,
    private nightshiftService: NightshiftService,
    private salesService: SalesService,
    private servicesService: ServicesService,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private gaService: GoogleAnalyticsService
  ) {
    this.coreService.loadCache();
    this.employeeService.loadCache();
    this.nightshiftService.loadCache();
    this.salesService.loadCache();
    this.servicesService.loadCache();
  }

  ngOnInit(): void {
    if (location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loaded = true;
    }, 1000);
    setTimeout(() => {
      this.loaded = true;
      this.cookieCheck();
    }, 1000);
  }

  open(content) {
    console.log(content);
    // eslint-disable-next-line max-len
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true }).result.then(
      (result) => {
        this.cookieConsentAnswer(result);
      }
    );
  }

  private cookieConsentAnswer(reason: any) {
    if (reason === 'deny') {
      this.cookieService.set('apotheke-pregaren_useCookies','0', { expires: new Date(new Date().getTime() +  1000 * 60 * 60 * 24 * 365) });
    } else if (reason === 'accept') {
      this.cookieService.set('apotheke-pregaren_useCookies','1', { expires: new Date(new Date().getTime() +  1000 * 60 * 60 * 24 * 365) });
      this.gaService.gtag('js', new Date());
      this.gaService.gtag('config', 'G-2Z6Q6STZ46');
    } else {
    }
  }

  private cookieCheck() {

    if (this.cookieService.check('apotheke-pregaren_useCookies')) {
      if (this.cookieService.get('apotheke-pregaren_useCookies') === '1') {
        this.gaService.gtag('js', new Date());
        this.gaService.gtag('config', 'G-2Z6Q6STZ46');
      } else {
      }
    } else {
      if (this.cookieConsent.first) {
        this.open(this.cookieConsent.first);
      }
    }
  }
}
