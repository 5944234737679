<div class="utility-page-wrap">
  <div class="utility-page-content w-password-page w-form">
    <form id="email-form" name="email-form" data-name="Email Form" class="utility-page-form w-password-page">

      <img src="https://d3e54v103j8qbb.cloudfront.net/static/password-page-lock.832ca8e2c9.svg" alt="">

      <h2>Geschütze Seite</h2>

      <input type="password" [(ngModel)]="password" class="w-password-page w-input" autofocus="true" maxlength="256" name="pass" data-name="field" placeholder="Passwort" id="field">
      <input type="submit" value="Submit" data-wait="Please wait..." class="w-password-page w-button" (click)="loginUser()">

    </form>
  </div>
</div>
