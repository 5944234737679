import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {HeaderService} from "../../services/header.service";
import {CoreService} from "../../services/core.service";
import {CoreInfo} from "../../dtos/coreInfo";
import {Service} from "../../dtos/service";
import {ServicesService} from "../../services/services.service";
import {ToastrService} from "ngx-toastr";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {DOCUMENT} from "@angular/common";
import {ContactService} from "../../services/contact.service";
import {Mail} from "../../dtos/mail";

gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  openHoursParsed = [];

  coreInfo: CoreInfo = {
    title: "...",
    subtitle: "...",
    featured1_title: "...",
    featured1_description: "...",
    featured1_url: "",
    featured2_title: "...",
    featured2_description: "...",
    featured2_url: "",
    featured3_title: "...",
    featured3_description: "...",
    featured3_url: "...",
    about_subheading: "...",
    about_heading: "...",
    about_catchText: "...",
    about_text: "...",
    oh_subheading: "...",
    oh_title: "...",
    ohd: "",
    contact_catchText: "...",
    contact_text: "...",
    address: "...",
    telephone: "...",
    email: "..."
  };

  contactForm = {
    name: '',
    email: '',
    subject: '',
    content: ''
  };

  services: Service[];
  services1: Service[];
  services2: Service[];

  @ViewChild('title', {static: true}) title: ElementRef<HTMLDivElement>;
  @ViewChild('services', {static: true}) servicesRef: ElementRef<HTMLDivElement>;
  constructor(public authService: AuthService, private headerService: HeaderService, private coreService: CoreService, private serviceService: ServicesService, private toastr: ToastrService, @Inject(DOCUMENT) private document: Document, private contactService: ContactService) { }

  ngOnInit() {
    this.initialAnimations();
    this.initScrollAnimation();
    this.coreService.getCoreInfo().subscribe({
      next: (res) => {
        this.coreInfo = res;
        this.parseOHD(this.coreInfo.ohd);
      },
      error: () => {
        this.toastr.error('Allgemeine Informationen konnten nicht geladen werden');
      }
    });
    this.serviceService.getAll().subscribe({
      next: (res) => {
        this.services = res.filter((service) => service.featured);
        this.services1 = [];
        this.services2 = [];
        if (this.services.length >= 1) {
          this.services2.push(this.services.pop());
        }
        if (this.services.length >= 1) {
          this.services2.push(this.services.pop());
        }
        if (this.services.length >= 1) {
          this.services1.push(this.services.pop());
        }
        if (this.services.length >= 1) {
          this.services1.push(this.services.pop());
        }
        this.services1 = this.services1.reverse();
        this.services2 = this.services2.reverse();
      },
      error: (err) => {
        this.toastr.error('Leistungen konnten nicht geladen werden');
      }
    });
  }

  parseOHD(ohd: string) {

    if (ohd) {
      if (ohd !== '') {
        const ohdp = ohd.split(';');

        this.openHoursParsed = [];

        for (const oh of ohdp) {
          this.openHoursParsed.push({left: oh.split('|')[0], right: oh.split('|')[1]});
        }
      }
    }

    return '';
  }

  submitContactForm() {
    if (this.contactForm.name === '' || this.contactForm.email === ''
      || this.contactForm.subject === '' || this.contactForm.content === '') {
      this.toastr.error('Bitte füllen sie das Kontaktformular vollständig aus');
    } else {
      const mail: Mail = {
        name: this.contactForm.name,
        address: this.contactForm.email,
        subject: this.contactForm.subject,
        message: this.contactForm.content
      };
      this.contactService.sendContactMessage(mail).subscribe({
        next: () => {
          this.contactForm.name = '';
          this.contactForm.email = '';
          this.contactForm.subject = '';
          this.contactForm.content = '';
          this.toastr.success('Nachricht wurde gesendet');
        },
        error: () => {
          this.toastr.error('Nachricht konnte nicht gesendet werden');
        }
      });
    }
  }

  private initScrollAnimation() {
  }

  private initialAnimations() {
    gsap.from(this.title.nativeElement.childNodes, {
      duration: 1,
      opacity: 0,
      y: -20,
      stagger: 0.8,
      delay: 0.5
    });
  }
}
