<div class="footer-section wf-section">
  <div class="footer-containner w-container">
    <a href="#" class="brand footer w-nav-brand a_webflow" routerLink="/administration" style="padding: 10px">
      <img src="assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg.png" width="55" srcset="assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg-p-500.png 500w, assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg.png 792w" sizes="55px" alt="">
      <div class="text-block">Administration</div>
    </a>
    <div>
      <a href="https://www.webflow.com" class="licenses w-inline-block"></a>
      <p class="conatct-info-text">{{coreInfo.address}}<br>{{coreInfo.telephone}} <br>{{coreInfo.email}}<br></p>
    </div>
    <a class="link a_webflow" routerLink="/impressum">IMPRESSUM</a>
  </div>
</div>
