import { Component, OnInit } from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {NightshiftService} from "../../../../../../services/nightshift.service";
import {NgxDropzoneChangeEvent} from "ngx-dropzone";

@Component({
  selector: 'app-image-upload-modal',
  templateUrl: './image-upload-modal.component.html',
  styleUrls: ['./image-upload-modal.component.scss']
})
export class ImageUploadModalComponent implements OnInit {

  files: File[] = [];

  // @ts-ignore
  constructor(protected ref: NbDialogRef<NachtdienstModalComponent>, private nachtdienstService: NightshiftService) {}

  remove() {
    this.ref.close({ img: null, save: true});
  }

  dismiss() {
    this.ref.close({ img: null, save: false});
  }

  save() {
    this.ref.close({ img: this.files[0], save: true});
  }

  ngOnInit(): void {
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    this.files = [$event.addedFiles.pop()];
  }

  onRemove(f: any) {

  }
}
