import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  // Observable string sources
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private disableGhostBarCallSource = new Subject<any>();

  // Observable string streams
  // eslint-disable-next-line @typescript-eslint/member-ordering
  disableGhostBarCalled$ = this.disableGhostBarCallSource.asObservable();

  // Service message commands
  disableGhostBar() {
    // @ts-ignore
    this.disableGhostBarCallSource.next();
  }
}
