import { Injectable } from '@angular/core';
import {Globals} from "../global/globals";
import {HttpClient} from "@angular/common/http";
import {Mail} from "../dtos/mail";
import {CoreInfo} from "../dtos/coreInfo";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private baseUri: string = this.globals.backendUri + '/contact';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  sendContactMessage(mail: Mail): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUri}`, mail);
  }
}
