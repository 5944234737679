import { Component, OnInit } from '@angular/core';
import {SalesService} from "../../../../../services/sales.service";
import {Sale} from "../../../../../dtos/sale";
import {Service} from "../../../../../dtos/service";
import {ServiceAddModalComponent} from "../edit-services/service-add-modal/service-add-modal.component";
import {SaleAddModalComponent} from "./sale-add-modal/sale-add-modal.component";
import {NbDialogService} from "@nebular/theme";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-edit-sales',
  templateUrl: './edit-sales.component.html',
  styleUrls: ['./edit-sales.component.scss']
})
export class EditSalesComponent implements OnInit {

  sales: Sale[];

  constructor(private salesService: SalesService, private dialogService: NbDialogService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.refresh();
  }

  open(sale: Sale) {
    this.dialogService.open(SaleAddModalComponent, {context: { sale }}).onClose
      .subscribe(() => {
        this.refresh();
      });
  }

  open2() {
    const sale = new Sale();

    this.dialogService.open(SaleAddModalComponent, {context: { sale }}).onClose
      .subscribe( () => {
        this.refresh();
      });
  }

  refresh() {
    this.salesService.getAll().subscribe({
      next: (res) => {
        this.sales = res;
      },
      error: (err) => {
        this.toastr.error('Angebote konnten nicht geladen werden');
      }
    });
  }
}
