import { Component, OnInit } from '@angular/core';
import {SidebarService} from "../../administration-services/sidebarService/sidebar.service";
import {NbMenuItem} from "@nebular/theme";
import {HeaderService} from "../../../../services/header.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  hide = false;
  menu: NbMenuItem[] = [];

  constructor(private sidebarService: SidebarService, private headerService: HeaderService) { }

  ngOnInit(): void {
    this.menu = this.sidebarService.getSidebarMenu();
    this.headerService.disableGhostBar();
  }

  tootgleNav() {
    this.hide = !this.hide;
  }

}
