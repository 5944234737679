export class Nightshift {
  id: number;
  location: string;
  date: string;
}

export class NightshiftFormatted {
  id: number;
  relDay: string;
  location: string;
  date: string;
}
