import { Component, OnInit } from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {NightshiftService} from "../../../../../../services/nightshift.service";
import {NgxDropzoneChangeEvent} from "ngx-dropzone";

@Component({
  selector: 'app-pdf-upload',
  templateUrl: './pdf-upload.component.html',
  styleUrls: ['./pdf-upload.component.scss']
})
export class PdfUploadComponent implements OnInit {

  files: File[] = [];

  // @ts-ignore
  constructor(protected ref: NbDialogRef<NachtdienstModalComponent>, private nachtdienstService: NightshiftService) {}


  dismiss() {
    this.ref.close({ pdf: null, save: false});
  }

  save() {
    this.ref.close({ pdf: this.files[0], save: true});
  }

  ngOnInit(): void {
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    this.files = [$event.addedFiles.pop()];
  }

  onRemove(f: any) {

  }

}
