<nb-layout>
  <nb-layout-column style="padding: 0">
    <div style="display: flex; flex-direction: row; margin-top: 80px; height: calc(100% - 80px)">
      <app-sidebar></app-sidebar>

      <nb-card style="margin: 15px; height: fit-content">
        <nb-card-header>Passwort ändern</nb-card-header>

        <nb-card-body>
          <form [formGroup]="form">
            <label>Altes Passwort</label>
            <input type="password" nbInput shape="rectangle" placeholder="Altes Passwort" style="margin-bottom: 15px" formControlName="oldPass">

            <label>Neues Passwort</label>
            <input type="password" nbInput shape="rectangle" placeholder="Neues Passwort" style="margin-bottom: 15px" formControlName="newPass1">

            <label>Neues Passwort wiederholen</label>
            <input type="password" nbInput shape="rectangle" placeholder="Neues Passwort wiederholen" style="margin-bottom: 15px" formControlName="newPass2">

            <div>
              <button type="submit" nbButton [status]="'primary'" outline style="margin-top: 15px" (click)="changePassword()">
                Passwort ändern
              </button>
            </div>
          </form>
        </nb-card-body>
      </nb-card>

    </div>
  </nb-layout-column>
</nb-layout>
