<nb-layout>
  <nb-layout-column style="padding: 0">
    <div style="display: flex; flex-direction: row; margin-top: 80px; height: calc(100% - 80px)">
      <app-sidebar></app-sidebar>

      <div style="width: 100%; margin: 15px; height: fit-content">
        <nb-card class="list-card" size="small" style="width: 100%; height: fit-content">
          <nb-card-header>Nachtdienste</nb-card-header>
          <nb-list>
            <nb-list-item class="selectable" *ngFor="let nightshift of nightshifts" (click)="open(nightshift)">
              {{nightshift.date}}, {{nightshift.location}}
            </nb-list-item>
            <nb-list-item class="selectable" (click)="open2()">
              +
            </nb-list-item>

          </nb-list>
        </nb-card>


        <nb-card class="list-card" size="small" style="width: 100%; height: fit-content">
          <nb-card-header>Nachtdienst Pdf</nb-card-header>
          <nb-card-footer>
            <button nbButton hero status="danger" (click)="deletePdf()" style="margin-right: 15px">Löschen</button>
            <button nbButton hero status="warning" (click)="changePdf()" style="margin-right: 15px">Ändern</button>
            <button nbButton hero status="primary" (click)="showPDF()">Anzeigen</button>
          </nb-card-footer>

        </nb-card>
      </div>

    </div>
  </nb-layout-column>
</nb-layout>
