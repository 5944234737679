import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {MessageComponent} from './components/message/message.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {httpInterceptorProviders} from './interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbMenuModule,
  NbCardModule,
  NbInputModule,
  NbButtonModule,
  NbListModule,
  NbIconModule, NbDialogService, NbDialogModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AdministrationComponent } from './components/administration/administration.component';
import { NachtdienstComponent } from './components/nachtdienst/nachtdienst.component';
import { TeamComponent } from './components/team/team.component';
import { AngeboteComponent } from './components/angebote/angebote.component';
import { LeistungenComponent } from './components/leistungen/leistungen.component';
import { PasswordComponent } from './components/administration/administration-components/password/password.component';
import { EditNachtdienstplanComponent } from './components/administration/administration-components/edit/edit-nachtdienstplan/edit-nachtdienstplan.component';
import { EditCoreComponent } from './components/administration/administration-components/edit/edit-core/edit-core.component';
import { SaveButtonHoverComponent } from './components/administration/administration-components/edit/save-button-hover/save-button-hover.component';
import { NachtdienstModalComponent } from './components/administration/administration-components/edit/edit-nachtdienstplan/nachtdienst-modal/nachtdienst-modal.component';
import { SidebarComponent } from './components/administration/administration-components/sidebar/sidebar.component';
import {config} from 'rxjs';
import { NachtdienstAddModalComponent } from './components/administration/administration-components/edit/edit-nachtdienstplan/nachtdienst-add-modal/nachtdienst-add-modal.component';
import { EditTeamComponent } from './components/administration/administration-components/edit/edit-team/edit-team.component';
import { ImageUploadModalComponent } from './components/administration/administration-components/edit/edit-team/image-upload-modal/image-upload-modal.component';
import {NgxDropzoneModule} from "ngx-dropzone";
import { EditServicesComponent } from './components/administration/administration-components/edit/edit-services/edit-services.component';
import { ServiceAddModalComponent } from './components/administration/administration-components/edit/edit-services/service-add-modal/service-add-modal.component';
import { EditSalesComponent } from './components/administration/administration-components/edit/edit-sales/edit-sales.component';
import { SaleAddModalComponent } from './components/administration/administration-components/edit/edit-sales/sale-add-modal/sale-add-modal.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import {ToastrModule} from "ngx-toastr";
import { PdfUploadComponent } from './components/administration/administration-components/edit/edit-nachtdienstplan/pdf-upload/pdf-upload.component';
import {NgxGoogleAnalyticsModule} from "ngx-google-analytics";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    MessageComponent,
    AdministrationComponent,
    NachtdienstComponent,
    TeamComponent,
    AngeboteComponent,
    LeistungenComponent,
    PasswordComponent,
    EditNachtdienstplanComponent,
    EditCoreComponent,
    SaveButtonHoverComponent,
    NachtdienstModalComponent,
    SidebarComponent,
    NachtdienstAddModalComponent,
    EditTeamComponent,
    ImageUploadModalComponent,
    EditServicesComponent,
    ServiceAddModalComponent,
    EditSalesComponent,
    SaleAddModalComponent,
    ImpressumComponent,
    PdfUploadComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({name: 'corporate'}),
    NbLayoutModule,
    NbEvaIconsModule,
    NbMenuModule.forRoot(),
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbListModule,
    NbIconModule,
    NbDialogModule.forRoot(),
    NgxDropzoneModule,
    ToastrModule.forRoot(),
    NgxGoogleAnalyticsModule
  ],
  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {
}
