import {Component, Input, OnInit} from '@angular/core';
import {Nightshift} from '../../../../../../dtos/nightshift';
import {NbDialogRef} from '@nebular/theme';
import {NightshiftService} from '../../../../../../services/nightshift.service';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-nachtdienst-add-modal',
  templateUrl: './nachtdienst-add-modal.component.html',
  styleUrls: ['./nachtdienst-add-modal.component.scss']
})
export class NachtdienstAddModalComponent implements OnInit {

  @Input() nachtdienst: Nightshift;
  backup: string;

  // @ts-ignore
  constructor(protected ref: NbDialogRef<NachtdienstModalComponent>, private nachtdienstService: NightshiftService, private toastr: ToastrService) {}

  dismiss() {
    this.ref.close();
  }

  save() {
    this.nachtdienstService.updateNightshift(this.nachtdienst).subscribe({
      next: () => {
        this.toastr.success('Nachtdienst hinzugefügt');
        this.ref.close();
      },
      error: (err) => {
        this.toastr.error('Nachtdienst konnte nicht hinzugefügt werden');
      }
    });
  }

  ngOnInit(): void {
  }


}
