<nb-card>
  <nb-card-header>Leistung ändern</nb-card-header>
  <nb-card-body>
    <label>Name</label>
    <input type="text" nbInput fullWidth status="info"  placeholder="Name" [(ngModel)]="service.name">

    <label>Beschreibung</label>
    <textarea rows="5" nbInput fullWidth shape="round"  placeholder="Beschreibung" [(ngModel)]="service.description"></textarea>

    <div class="icon-service selectable-img" (click)="open()" *ngIf="!service.img"><img src="assets/images/no-photo.png" style="padding: 7px"></div>
    <div class="icon-service selectable-img" (click)="open()" *ngIf="service.img"><img [src]="service.img" style="padding: 7px"></div>

    <label>Link</label>
    <input type="text" nbInput fullWidth status="info"  placeholder="link" [(ngModel)]="service.link">

  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="danger" (click)="delete()" style="margin-right: 15px" *ngIf="service.id">Löschen</button>
    <button nbButton hero status="warning" (click)="dismiss()">Abbrechen</button>
    <button nbButton hero status="primary" (click)="save()" style="margin-left: 15px">Speichern</button>
  </nb-card-footer>
</nb-card>
