import {Component, HostListener, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {HeaderService} from '../../services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // eslint-disable-next-line eqeqeq
  showGhostBar: boolean = window.scrollY == 0;
  showDropdown = false;

  disabled = false;

  navLinks = [
    {title: 'Über uns', url: '/'},
    {title: 'Nachtdienstplan', url: '/nachtdienst'},
    {title: 'Leistungen', url: '/leistungen'},
    {title: 'Team', url: '/team'},
    {title: 'Angebote', url: '/angebote'},
  ];

  constructor(public authService: AuthService, private headerService: HeaderService) { }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (!this.disabled) {
      // eslint-disable-next-line eqeqeq
      this.showGhostBar = window.scrollY == 0;
    }
  }

  ngOnInit() {
    this.headerService.disableGhostBarCalled$.subscribe(() => {
      this.disableGhostBar();
    });
  }

  disableGhostBar() {
    this.disabled = true;
    this.showGhostBar = false;
  }

  toogleDropdown() {
    this.showDropdown = !this.showDropdown;
  }
}
