<div style="position: fixed; width: 100%">
  <div data-collapse="medium" data-animation="default" data-duration="400" data-doc-height="1" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav hider" style="background: transparent">
    <div class="navigation-div w-clearfix">
      <a routerLink="/" (click)="showDropdown = false" aria-current="page" class="brand w-nav-brand w--current a_webflow" style="margin: 0; padding: 0 30px;border-color: gray;">
        <img src="assets/images/webflow/white-792px-Apotheke_Oesterreich_Logo.svg.png" width="48" srcset="assets/images/webflow/white-792px-Apotheke_Oesterreich_Logo.svg-p-500.png 500w, assets/images/webflow/white-792px-Apotheke_Oesterreich_Logo.svg.png 792w" sizes="(max-width: 479px) 43vw, 48px" alt="">
      </a>
      <div class="menu-button w-nav-button" (click)="toogleDropdown()">
        <div class="icon w-icon-nav-menu"></div>
      </div>
      <nav role="navigation" class="nav-menu w-nav-menu">
        <a *ngFor="let link of navLinks" [routerLink]=link.url class="nav-link w-nav-link a_webflow" style="border-color: gray; color: white">{{link.title}}</a>
      </nav>
    </div>
  </div>
</div>

<div class="w-nav" style="position: fixed; width: 100%; height: 0">

<div data-collapse="medium" data-animation="default" data-duration="400" data-doc-height="1" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav hider" style="background: white"
  [class.hide]="showGhostBar"
>
  <div class="navigation-div w-clearfix">
    <a routerLink="/" (click)="showDropdown = false" aria-current="page" class="brand w-nav-brand w--current a_webflow" style="margin: 0; padding: 0 30px;border-color: gray;">
      <img src="assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg.png" width="48" srcset="assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg-p-500.png 500w, assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg.png 792w" sizes="(max-width: 479px) 43vw, 48px" alt="">
    </a>
    <div class="menu-button w-nav-button" style="border-color: gray" (click)="toogleDropdown()">
      <div class="icon w-icon-nav-menu" style="color: black;"></div>
    </div>
    <nav role="navigation" class="nav-menu w-nav-menu">
      <a *ngFor="let link of navLinks" [routerLink]=link.url class="nav-link w-nav-link a_webflow" style="border-color: gray;">{{link.title}}</a>
    </nav>
  </div>
</div>

  <div (click)="showDropdown=false" class="background-dropdown" [class.background-dropdown-extended]="showDropdown">
    <div class="dropdown-navbar" [class.hide-navbar]="showDropdown">
      <a *ngFor="let link of navLinks" class="selectable a_webflow" [routerLink]=link.url style="border-color: gray; color: white; ">{{link.title}}</a>
    </div>
  </div>
</div>
<!--<div data-animation="default" class="navbar-scroll w-nav" data-easing2="ease" data-easing="ease" data-collapse="medium" data-w-id="a872d0e6-916a-080d-769f-d73f43e74ba7" role="banner" style="position: sticky">
  <div class="navigation-div black w-clearfix">
    <a href="index.html" aria-current="page" class="brand black w-nav-brand w&#45;&#45;current"><img src="assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg.png" width="50" srcset="assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg-p-500.png 500w, assets/images/webflow/792px-Apotheke_Oesterreich_Logo.svg.png 792w" sizes="(max-width: 767px) 100vw, 50px" alt=""></a>
    <div class="menu-button black w-nav-button">
      <div class="icon black w-icon-nav-menu"></div>
    </div>
    <nav role="navigation" class="nav-menu white w-nav-menu">
      <a href="#Home" class="nav-link black w-nav-link">Über Uns</a>
      <a href="#About-Us" class="nav-link black w-nav-link">Nachtdienstplan</a>
      <a href="#" class="nav-link black w-nav-link">Leistungen</a>
      <a href="#" class="nav-link black w-nav-link">Apothekenkatalog</a>
      <a href="#Skills" class="nav-link black w-nav-link">Skills</a>
      <a href="#Process" class="nav-link black w-nav-link">Angebote</a>
      <a href="#Contact-Us" class="nav-link black w-nav-link">Kontakt</a>
    </nav>
  </div>
</div>-->
