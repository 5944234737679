import {Component, Input, OnInit} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import {Nightshift} from '../../../../../../dtos/nightshift';
import {NightshiftService} from '../../../../../../services/nightshift.service';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-nachtdienst-modal',
  templateUrl: './nachtdienst-modal.component.html',
  styleUrls: ['./nachtdienst-modal.component.scss']
})
export class NachtdienstModalComponent implements OnInit {

  @Input() nachtdienst: Nightshift;
  backup: string;

  // @ts-ignore
  constructor(protected ref: NbDialogRef<NachtdienstModalComponent>, private nachtdienstService: NightshiftService, private toastr: ToastrService) {}

  dismiss() {
    this.ref.close();
  }

  save() {
    this.nachtdienstService.updateNightshift(this.nachtdienst).subscribe({
      next: () => {
        this.toastr.success('Änderungen gespeichert');
        this.ref.close();
      },
      error: (err) => {
        this.toastr.error('Änderungen konnten nicht gespeichert werden');
      }
    });
  }

  ngOnInit(): void {
  }

  remove() {
    if (this.nachtdienst.id) {
      this.nachtdienstService.deleteNightshift(this.nachtdienst.id).subscribe({
        next: () => {
          this.toastr.success('Nachtdienst gelöscht');
          this.ref.close();
        },
        error: (err) => {
          this.toastr.error('Nachtdienst konnte nicht gelöscht werden');
        }
      });
    }
  }
}
