<nb-layout>
  <nb-layout-column style="padding: 0">
    <div style="display: flex; flex-direction: row; margin-top: 80px; height: calc(100% - 80px)">
      <app-sidebar></app-sidebar>


      <nb-card class="list-card" size="small" style="width: 100%; margin: 15px; height: fit-content">
        <nb-card-header>Angebote</nb-card-header>
        <nb-list>
          <nb-list-item class="selectable" *ngFor="let sale of sales" (click)="open(sale)">
            <div style="margin-left: 8px; margin-top: 3px;">
              {{sale.product}}
            </div>
          </nb-list-item>
          <nb-list-item class="selectable" (click)="open2()">
            +
          </nb-list-item>

        </nb-list>
      </nb-card>

    </div>
  </nb-layout-column>
</nb-layout>
