import { Component, OnInit } from '@angular/core';
import {ServicesService} from '../../services/services.service';
import {Service} from '../../dtos/service';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-leistungen',
  templateUrl: './leistungen.component.html',
  styleUrls: ['./leistungen.component.scss']
})
export class LeistungenComponent implements OnInit {

  services: Service[] = [];

  constructor(private servicesService: ServicesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.servicesService.getAll().subscribe({
      next: (res) => {
        this.services = res;
      },
      error: (err) => {
        this.toastr.error('Leistungen konnten nicht geladen werden');
      }
    });
  }

}
