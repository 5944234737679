import { Component, OnInit } from '@angular/core';
import {NightshiftService} from "../../services/nightshift.service";
import {Nightshift, NightshiftFormatted} from "../../dtos/nightshift";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-nachtdienst',
  templateUrl: './nachtdienst.component.html',
  styleUrls: ['./nachtdienst.component.scss']
})
export class NachtdienstComponent implements OnInit {

  today = '';
  tomorrow = '';
  days: string[] = [];
  nightshifts: NightshiftFormatted[];

  constructor(private nightshiftService: NightshiftService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.nightshiftService.getAllFormatted().subscribe({
      next: (res) => {
        this.nightshifts = res;
      },
      error: (err) => {
        this.toastr.error('Nachtdienste konnten nicht geladen werden');
      }
    });
  }



  showPDF() {
    this.nightshiftService.getNightshiftPdf().subscribe({
      next: (pdf) => {
        if (pdf.pdf == null) {
          this.toastr.error('Das Dokument ist aktuell nicht verfügbar');
        } else {
          const pdfURL = URL.createObjectURL(this.dataURItoBlob(pdf.pdf));
          window.open(pdfURL, '_blank');
        }
      }
    });
  }


  private dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});


  }

  private toDaySting(day: number) {
    switch (day) {
      case 1:
        return 'Mo';
      case 2:
        return 'Di';
      case 3:
        return 'Mi';
      case 4:
        return 'Do';
      case 5:
        return 'Fr';
      case 6:
        return 'Sa';
      case 0:
        return 'So';
      default:
        return '';
    }
  }
}
