<nb-card>
  <nb-card-header>Nachtdienst hinzufügen</nb-card-header>
  <nb-card-body>
    <label>Datum</label>
    <input type="text" nbInput fullWidth status="info"  placeholder="Datum" [(ngModel)]="nachtdienst.date">

    <label>Beschreibung</label>
    <input type="text" nbInput fullWidth status="info"  placeholder="Beschreibung" [(ngModel)]="nachtdienst.location">

  </nb-card-body>
  <nb-card-footer>
    <button nbButton hero status="warning" (click)="dismiss()">Abbrechen</button>
    <button nbButton hero status="primary" (click)="save()" style="margin-left: 15px">Speichern</button>
  </nb-card-footer>
</nb-card>
