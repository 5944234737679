import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {AuthGuard} from './guards/auth.guard';
import {MessageComponent} from './components/message/message.component';
import {AdministrationComponent} from './components/administration/administration.component';
import {NachtdienstComponent} from './components/nachtdienst/nachtdienst.component';
import {TeamComponent} from './components/team/team.component';
import {AngeboteComponent} from './components/angebote/angebote.component';
import {LeistungenComponent} from './components/leistungen/leistungen.component';
import {LoggedOutGuard} from './guards/logged-out.guard';
import {LogOutGuard} from './guards/log-out.guard';
import {PasswordComponent} from './components/administration/administration-components/password/password.component';
import {
  EditNachtdienstplanComponent
} from './components/administration/administration-components/edit/edit-nachtdienstplan/edit-nachtdienstplan.component';
import {
  EditCoreComponent
} from './components/administration/administration-components/edit/edit-core/edit-core.component';
import {
  EditTeamComponent
} from './components/administration/administration-components/edit/edit-team/edit-team.component';
import {
  EditServicesComponent
} from './components/administration/administration-components/edit/edit-services/edit-services.component';
import {
  EditSalesComponent
} from './components/administration/administration-components/edit/edit-sales/edit-sales.component';
import {ImpressumComponent} from './components/impressum/impressum.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'administration', canActivate: [AuthGuard], component: AdministrationComponent},
  {path: 'administration/logout', canActivate: [LogOutGuard], component: HomeComponent},
  {path: 'administration/passwort', canActivate: [AuthGuard], component: PasswordComponent},
  {path: 'administration/nachtdienst', canActivate: [AuthGuard], component: EditNachtdienstplanComponent},
  {path: 'administration/team', canActivate: [AuthGuard], component: EditTeamComponent},
  {path: 'administration/allgemein', canActivate: [AuthGuard], component: EditCoreComponent},
  {path: 'administration/leistungen', canActivate: [AuthGuard], component: EditServicesComponent},
  {path: 'administration/angebote', canActivate: [AuthGuard], component: EditSalesComponent},
  {path: 'nachtdienst', component: NachtdienstComponent},
  {path: 'team', component: TeamComponent},
  {path: 'angebote', component: AngeboteComponent},
  {path: 'leistungen', component: LeistungenComponent},
  {path: 'impressum', component: ImpressumComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
