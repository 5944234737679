<div class="hero-section wf-section">
  <div class="containner-slide w-container">
    <h1 data-w-id="e8c3a2a0-91d4-fea1-73bf-8822a8112a19" class="hero-heading second">Leistungen</h1>
  </div>
</div>
<div class="project-section wf-section">
  <div class="w-container" style="padding-bottom:100px">

    <div class="working-process-wrapper" *ngFor="let service of services" style="margin:15px">
     <div class="icon-service"> <a [href]="service.link"><img [src]="service.img" style="padding: 7px"></a></div>
      <h3>{{service.name}}</h3>
      <div data-w-id="8a092700-995f-781f-69f3-7618aad0f8c4" class="skills-containner">
        <div style="width:0PX" class="skills-progress-line"></div>
      </div>
      <p>{{service.description}}</p>
    </div>

  </div>
</div>
