<nav class="hero-section centered home title-hero-section" style="height: 100vh; display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed;
">
  <div #title data-w-id="e464d218-f801-55d1-1f50-7da00b5bfb8f" class="container w-container title-hero">
    <h1 data-w-id="af901219-579c-2691-e855-549d3168e8a2" style="opacity:1; line-height:66px" class="hero-heading">{{coreInfo.title}}</h1>
    <h1 data-w-id="a53ee65d-8504-36ad-2d31-9fe54a958274" style="opacity:1" class="hero-subheader">{{coreInfo.subtitle}}</h1>
  </div>
</nav>

<section #services id="Service" class="features-section wf-section">
  <div class="w-row stretchout2">
    <div class="column w-col w-col-4" style="padding-left: 0; padding-right: 0; z-index: 0;">
      <div class="features-div stretchout">
        <div class="top-aligned">
          <img src="../../../assets/images/webflow/pixel-perfect.svg" width="40" alt="">
          <h3 class="title-features">{{coreInfo.featured1_title}}</h3>
          <p class="features-text">{{coreInfo.featured1_description}}</p>
        </div>
        <div class="bottom-aligned">
          <a [routerLink]="coreInfo.featured1_url" class="button hollow">Weiter</a>
        </div>
      </div>
    </div>
    <div class="column w-col w-col-4" style="padding-left: 0; padding-right: 0">
      <div class="features-div second stretchout">
        <div class="top-aligned">
          <img src="../../../assets/images/webflow/awesome.svg" width="40" alt="">
          <h3 class="title-features">{{coreInfo.featured2_title}}</h3>
          <p class="features-text">{{coreInfo.featured2_description}}</p>
        </div>
        <div class="bottom-aligned">
          <a [routerLink]="coreInfo.featured2_url" class="button hollow">Weiter</a>
        </div>

      </div>
    </div>
    <div class="column w-col w-col-4" style="padding-left: 0; padding-right: 0">
      <div class="features-div third stretchout">
        <div class="top-aligned">
          <img src="../../../assets/images/webflow/responsive.svg" width="40" alt="">
          <h3 class="title-features">{{coreInfo.featured3_title}}</h3>
          <p class="features-text">{{coreInfo.featured3_description}}</p>
        </div>
        <div class="bottom-aligned">
          <a [routerLink]="coreInfo.featured3_url" class="button hollow">Weiter</a>
        </div>
      </div>
    </div>
  </div>
</section>
<div id="About-Us" class="section about wf-section">
  <div class="w-container">
    <div class="div-block">
      <div class="about-us-div half-side">
        <img src="../../../assets/images/stannalogo.png" alt="" style="object-fit: cover; margin-bottom: 10px">
        <p><strong><em class="emphasized-text">{{coreInfo.about_catchText}}</em></strong></p>
        <p [innerHTML]="coreInfo.about_text"></p>
      </div>
    </div>
  </div>
</div>
<div id="Skills" class="skills-section wf-section">
  <div class="w-container">
    <h5 class="upperheading-white">{{coreInfo.oh_subheading}}</h5>
    <h2 class="section-title white">{{coreInfo.oh_title}}</h2>

      <div class="w-col w-col-6" *ngFor="let oh of openHoursParsed">
        <div class="skills-wrapper">
          <div class="w-row">
            <div class="w-col w-col-6">
              <h5 class="skills-title" style="margin-left: 10px; margin-right: 10px">{{oh?.left}}</h5>
            </div>
            <div class="w-col w-col-6">
              <h5 class="skills-values" style="margin-left: 10px; margin-right: 10px">{{oh?.right}}</h5>
            </div>
          </div>
          <div data-w-id="72f4fa40-8d11-f2c5-4d49-d55ce78f142e" class="skills-containner">
            <div data-ix="skills-progress-line-4" class="skills-progress-line photography"></div>
          </div>
        </div>
      </div>



  </div>
</div>
<div id="Process" class="section process wf-section">
  <div class="w-container">
    <h5>einige unserer</h5>
    <h2 class="section-title">leistungen</h2>
    <div class="process-row w-row">
      <div class="w-col w-col-4 mobile-center">
        <img src="../../../assets/images/webflow/Phone-6-Plus-Gold.png" height="700" alt="">
      </div>
      <div class="process-column first w-col w-col-4 mobile-stretch">

        <div class="working-process-wrapper" *ngFor="let service of services1">
          <div class="icon-service"><img [src]="service.img" style="padding: 7px"></div>
          <h3>{{service?.name}}</h3>
          <p>{{service?.description}}</p>
        </div>

      </div>
      <div class="process-column second w-col w-col-4 mobile-stretch">
        <div class="working-process-wrapper" *ngFor="let service of services2">
          <div class="icon-service"><img [src]="service.img" style="padding: 7px"></div>
          <h3>{{service?.name}}</h3>
          <p>{{service?.description}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container w-container">
    <a routerLink="leistungen" class="button hollow">alle leistungen anzeigen</a>
  </div>
</div>

<div id="Contact-Us" class="contact-us-section wf-section">
  <h2 class="section-title">kontakt</h2>
  <div class="w-container">
    <div class="contact-row w-row">
      <div class="w-col w-col-6">
        <div class="form-wrapper w-form">
          <form id="email-form" name="email-form" data-name="Email Form" method="get">
            <label for="name" class="form-text">Name:</label>
            <input type="text" class="form-field w-input" maxlength="256" name="name" data-name="Name" placeholder="" id="name" required="" [(ngModel)]="contactForm.name">

            <label for="email" class="form-text">Email Adresse:</label>
            <input type="email" class="form-field w-input" maxlength="256" name="email" data-name="Email" placeholder="" id="email" required="" [(ngModel)]="contactForm.email">

            <label for="Subject" class="form-text">Betreff:</label>
            <input type="text" class="form-field w-input" maxlength="256" name="Subject" data-name="Subject" placeholder="" id="Subject" required="" [(ngModel)]="contactForm.subject">

            <label for="Message" class="form-text">Nachricht:</label>
            <textarea id="Message" name="Message" maxlength="5000" data-name="Message" required="" placeholder="" class="form-field message w-input" [(ngModel)]="contactForm.content"></textarea>

            <input type="submit" value="Senden" data-wait="Please wait..." class="submit-button w-button" (click)="submitContactForm()">

          </form>
        </div>
      </div>
      <div class="contact-column w-col w-col-6" style="color: black; text-align: right">
        <p class="emphasized-text right"><strong><em class="emphasized-text">{{coreInfo.contact_catchText}}</em></strong></p>
        <p class="conatct-info-text">{{coreInfo.contact_text}}</p>
        <p class="conatct-info-text address"><strong class="important-text">ADRESSE<br></strong>{{coreInfo.address}}<br></p>
        <p class="conatct-info-text"><strong class="important-text">TELEFON &amp; EMAIL<br></strong>{{coreInfo.telephone}}<br>{{coreInfo.email}}<br></p>
      </div>
    </div>
  </div>
</div>
