<nb-layout>
  <nb-layout-column style="padding: 0">
    <div style="display: flex; flex-direction: row; margin-top: 80px; height: calc(100% - 80px)">
      <app-sidebar></app-sidebar>

      <div id="Team" class="section wf-section" style="margin: 15px">
        <div class="w-container">
          <div class="selectable" (click)="open(-1)" *ngIf="!imgGroup">
            <img src="assets/images/webflow/gruppenfotos-tipps-und-tricks-1024x683.jpg" loading="lazy" alt="">
          </div>

          <div class="selectable" (click)="open(-1)" *ngIf="imgGroup">
            <img [src]="imgGroup" loading="lazy" alt="">
          </div>

          <div class="team-row w-row">
            <div class="w-col w-col-4" style="margin-bottom: 15px" *ngFor="let employee of employees; let i = index">
              <div class="team-wrapper">
                <div class="selectable" *ngIf="!employee.img" (click)="open(i)">
                  <img src="assets/images/webflow/man.png" alt="">
                </div>
                <div class="selectable" *ngIf="employee.img" (click)="open(i)">
                  <img [src]="employee.img" alt="">
                </div>
                <input type="text" nbInput shape="rectangle" fullWidth placeholder="Name" style="margin-bottom: 15px" style="min-width: 50%" [(ngModel)]="employee.name">
                <input type="text" nbInput shape="rectangle" fullWidth placeholder="Beschäftigung" style="margin-bottom: 15px" style="min-width: 50%" [(ngModel)]="employee.occupation">
                <button nbButton hero status="danger" (click)="removeEmployee(i)" style="width: 50%">Löschen</button>
                <button nbButton hero status="success" (click)="saveEmployee(i)" style="width: 50%">Speichern</button>
              </div>
            </div>
            <div class="w-col w-col-4" style="margin-bottom: 15px" (click)="addEmployee()">
              <div class="team-wrapper">
                <div class="selectable" style="border: 2px solid black; display: flex; justify-content: center; align-items: center">
                  <div class="team-wrapper" style="opacity: 0">
                    <div>
                      <img src="assets/images/webflow/58ecee4033fe601d4301a838_team-1.jpg" alt="">
                    </div>
                    <input type="text" nbInput shape="rectangle" fullWidth placeholder="Name" style="margin-bottom: 15px" style="min-width: 50%">
                    <input type="text" nbInput shape="rectangle" fullWidth placeholder="Beschäftigung" style="margin-bottom: 15px" style="min-width: 50%">
                    <button nbButton hero status="danger" style="width: 50%">Löschen</button>
                    <button nbButton hero status="success" style="width: 50%">Speichern</button>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16" style="position: absolute">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </nb-layout-column>
</nb-layout>

