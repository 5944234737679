import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {map, Observable, of, shareReplay} from "rxjs";
import {Message} from "../dtos/message";
import {CoreInfo} from "../dtos/coreInfo";
import {tap} from "rxjs/operators";
import {NightshiftFormatted} from "../dtos/nightshift";

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  private cachedCoreInfo$: CoreInfo;

  private baseUri: string = this.globals.backendUri + '/core';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  getCoreInfo(): Observable<CoreInfo> {
    if (!this.cachedCoreInfo$) {
      this.loadCache();
      return this.httpClient.get<CoreInfo>(this.baseUri);
    }
    return of(this.cachedCoreInfo$);
  }

  putCoreInfo(coreInfo: CoreInfo): Observable<CoreInfo> {
    this.invalidateCache();
    return this.httpClient.put<CoreInfo>(`${this.baseUri}`, coreInfo);
  }

  loadCache() {
    this.httpClient.get<CoreInfo>(this.baseUri).subscribe({
      next: (res) => {
        this.cachedCoreInfo$ = res;
      }
    });
  }

  private invalidateCache() {
    this.cachedCoreInfo$ = null;
  }
}
