import { Component, OnInit } from '@angular/core';
import {HeaderService} from "../../../../../services/header.service";
import {SidebarService} from "../../../administration-services/sidebarService/sidebar.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NbMenuItem} from "@nebular/theme";
import {CoreInfo} from "../../../../../dtos/coreInfo";
import {CoreService} from "../../../../../services/core.service";
import {Service} from "../../../../../dtos/service";
import {ServicesService} from "../../../../../services/services.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-edit-core',
  templateUrl: './edit-core.component.html',
  styleUrls: ['./edit-core.component.scss']
})
export class EditCoreComponent implements OnInit {

  coreInfo: CoreInfo = {
    title: "...",
    subtitle: "...",
    featured1_title: "...",
    featured1_description: "...",
    featured1_url: "",
    featured2_title: "...",
    featured2_description: "...",
    featured2_url: "",
    featured3_title: "...",
    featured3_description: "...",
    featured3_url: "...",
    about_subheading: "...",
    about_heading: "...",
    about_catchText: "...",
    about_text: "...",
    oh_subheading: "...",
    oh_title: "...",
    ohd: "",
    contact_catchText: "...",
    contact_text: "...",
    address: "...",
    telephone: "...",
    email: "..."
  };

  form: FormGroup;

  constructor(
    private headerService: HeaderService,
    private sidebarService: SidebarService,
    private formBuilder: FormBuilder,
    private coreService: CoreService,
    private serviceService: ServicesService,
    private toastr: ToastrService
  ) { }

  menu: NbMenuItem[] = [];

  ngOnInit(): void {
    this.headerService.disableGhostBar();
    this.menu = this.sidebarService.getSidebarMenu();

    this.resetForm();

    this.coreService.getCoreInfo().subscribe({
      next: (res) => {
        this.coreInfo = res;
        this.resetForm();
      },
      error: () => {
        this.toastr.error('Es ist ein unerwarteter Fehler aufgetreten');
      }
    });
  }

  private resetForm() {
    this.form = this.formBuilder.group({
      title: [this.coreInfo.title],
      subtitle: [this.coreInfo.subtitle],
      featured1_title: [this.coreInfo.featured1_title],
      featured1_description: [this.coreInfo.featured1_description],
      featured1_url: [this.coreInfo.featured1_url],
      featured2_title: [this.coreInfo.featured2_title],
      featured2_description: [this.coreInfo.featured2_description],
      featured2_url: [this.coreInfo.featured2_url],
      featured3_title: [this.coreInfo.featured3_title],
      featured3_description: [this.coreInfo.featured3_description],
      featured3_url: [this.coreInfo.featured3_url],
      about_subheading: [this.coreInfo.about_subheading],
      about_heading: [this.coreInfo.about_heading],
      about_catchText: [this.coreInfo.about_catchText],
      about_text: [this.coreInfo.about_text],
      oh_subheading: [this.coreInfo.oh_subheading],
      oh_title: [this.coreInfo.oh_title],
      ohd: [
        this.coreInfo.ohd
      ],
      contact_catchText: [this.coreInfo.contact_catchText],
      contact_text: [this.coreInfo.contact_text],
      address: [this.coreInfo.address],
      telephone: [this.coreInfo.telephone],
      email: [this.coreInfo.email]
    });
  }

  updateCoreInfo() {
    this.coreInfo = {
      title: this.form.value.title,
      subtitle: this.form.value.subtitle,
      featured1_title: this.form.value.featured1_title,
      featured1_description: this.form.value.featured1_description,
      featured1_url: this.form.value.featured1_url,
      featured2_title: this.form.value.featured2_title,
      featured2_description: this.form.value.featured2_description,
      featured2_url: this.form.value.featured2_url,
      featured3_title: this.form.value.featured3_title,
      featured3_description: this.form.value.featured3_description,
      featured3_url: this.form.value.featured3_url,
      about_subheading: this.form.value.about_subheading,
      about_heading: this.form.value.about_heading,
      about_catchText: this.form.value.about_catchText,
      about_text: this.form.value.about_text,
      oh_subheading: this.form.value.oh_subheading,
      oh_title: this.form.value.oh_title,
      ohd: this.form.value.ohd,
      contact_catchText: this.form.value.contact_catchText,
      contact_text: this.form.value.contact_text,
      address: this.form.value.address,
      telephone: this.form.value.telephone,
      email: this.form.value.email
    };
  }

  save() {
    this.updateCoreInfo();
    this.coreService.putCoreInfo(this.coreInfo).subscribe({
      next: () => {
        this.toastr.success('Änderungen gespeichert');
      },
      error: (err) => {
        this.toastr.error('Es ist ein unerwarteter Fehler aufgetreten');
      }
    });
  }

  cancel() {
    this.resetForm();
  }
}
