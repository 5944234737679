import { Injectable } from '@angular/core';
import {NbMenuItem} from "@nebular/theme";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor() { }

  menu: NbMenuItem[] = [
    {
      title: 'Dashboard',
      icon: 'home-outline',
      link: '/administration',
      home: true,
    },
    {
      title: 'Inhalte anpassen',
      icon: 'edit-2-outline',
      children:  [
        {
          title: 'Startseite',
          link: '/administration/allgemein',
        },
        {
          title: 'Nachtdienstplan',
          link: '/administration/nachtdienst',
        },
        {
          title: 'Leistungen',
          link: '/administration/leistungen',
        },
        {
          title: 'Team',
          link: '/administration/team',
        },
        {
          title: 'Angebote',
          link: '/administration/angebote',
        },
        /*{
          title: 'Apothekenkatalog',
          link: '/administration/adpothekenkatalog',
        },*/
      ]
    },
    {
      title: 'Passwort ändern',
      icon: 'unlock-outline',
      link: '/administration/passwort',
    },
    {
      title: 'Abmelden',
      icon: 'log-out-outline',
      link: '/administration/logout',
    },
  ];

  getSidebarMenu() {
    return this.menu;
  }
}
